import React from 'react'
import * as Unicons from '@iconscout/react-unicons';
import logoAnsgraph from '../../assets/imgs/logo_ansgraph.png'

export default function Footer() {
  return (
    <div>
        <section className="has-bg-img py-0">
            <div className="container">
                <div className="footer">
                    <div className="footer-lists">
                        <ul className="list">
                            <li className="list-head">
                                <h6 className="font-weight-bold">ANSGRAPH</h6>
                            </li>
                            <li className="list-body">
                                <a href="#" className="logo">
                                    <img src={logoAnsgraph} alt="#"/>
                                    <h6>Ansgraph</h6>
                                </a>
                                <p>Akan sangat gembira bagi kami apablia terjalin kerjasama antara perusahaan anda dengan perusahaan kami.</p> 
                                <p className="mt-3">
                                    Copyright &copy; 2024 <a className="d-inline text-primary" href="https://ansgraph.com">Ansgraph</a>
                                </p>                   
                            </li>
                        </ul>
                        <ul className="list">
                            <li className="list-head">
                                <h6 className="font-weight-bold">USEFUL LINKS</h6>
                            </li>
                            <li className="list-body">
                                <div className="row">
                                    <div className="col">
                                        <a href="#about">About</a>
                                        <a href="#service">Service</a>
                                        <a href="#client">Client</a>
                                        <a href="#team">Teams</a>
                                    </div>
                                    <div className="col">
                                        <a href="#contact">Contact</a>
                                        <a href="#">Faq</a>
                                        <a href="#">Privacy Policy</a>                  
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className="list">
                            <li className="list-head">
                                <h6 className="font-weight-bold">CONTACT INFO</h6>
                            </li>
                            <li className="list-body">
                                <p>Contact us and we'll get back to you within 24 hours.</p>
                                <p><Unicons.UilLocationPoint/>Jl. Pemuda no.37 B4 Taman Sari Bukit Damai</p>
                                <p><Unicons.UilFastMail/>  official@ansgraph.com</p>
                                <div className="social-links">
                                    <a href="javascript:void(0)" className="link"><i className="ti-facebook"></i></a>
                                    <a href="javascript:void(0)" className="link"><i className="ti-twitter-alt"></i></a>
                                    <a href="javascript:void(0)" className="link"><i className="ti-google"></i></a>
                                    <a href="javascript:void(0)" className="link"><i className="ti-pinterest-alt"></i></a>
                                    <a href="javascript:void(0)" className="link"><i className="ti-instagram"></i></a>
                                    <a href="javascript:void(0)" className="link"><i className="ti-rss"></i></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>    
            </div>
        </section>
        
    </div>
  )
}
