import React from 'react'
import '../../assets/css/creative-studio.css'
import About from './About'
import Client from './Client'
import Contact from './Contact'
import Footer from './Footer'
import Navbar from './Navbar'
import Service from './Service'
import Teams from './Teams'

export default function IndexAnsgraph() {
  return (
  <>
    <Navbar/>
    <About/>
    <Service/>
    <Client/>
    <Teams/>
    <Contact/>
    <Footer/>
  </>
  )
}
