import React from 'react'
import * as Unicons from '@iconscout/react-unicons';
import logoAnsgraph from '../../assets/imgs/logo_ansgraph.png'

export default function About() {
    
    return (
        <>
            <section id="about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 col-lg-4">
                            <img src={logoAnsgraph} alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, Creative studio Landing page" className="w-100 img-thumbnail mb-3"/>
                        </div>
                        <div className="col-md-7 col-lg-8">
                            <h6 className="section-subtitle mb-0">PT. Ansgraph Digital Kreatif</h6>
                            <h6 className="section-title mb-3">Visi & Misi</h6>

                            <p>Visi : Menjadi perusahaan jasa kreatif terbaik dan terpercaya di Indonesia</p>
                            <span>
                                Misi :
                                <br/>1. Mengahasilkan produk visual yang <b>berkualitas tinggi</b> dan <b>inovatif</b>
                                <br/>2. Memberikan layanan terbaik dengan hara yang <b>reasonable</b> dan <b>kompetitif</b> bagi Kelebihan
                                <br/>3. Mewujudkan ide dan inovasi melalui <b>teknologi digital</b> agar memenuhi kebutuhan <b>komersial</b> yang dibutuhkan oleh klien
                                <br/>4. Melakukan pekerjaan yang <b>efektif</b> dalam proses produksi agar <b>terciptanya kedisiplinan</b> dalam timeline produksi, sehingga pekerjaan dapat selesai tepat waktu
                                <br/>5. Peningkatan <b>kompetensi SDM, teknologi</b> secara berkelanjutan.
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="has-bg-img py-md">
                <div className="container text-center">
                    <h6 className="section-subtitle">We See</h6>
                    <h6 className="section-title mb-6">What Other Don't See.</h6>
                    <div className="widget mb-4">
                        <div className="widget-item">
                            <Unicons.UilCalender color={'#249ecc'}/>
                            <h4 style={{marginTop:'10px'}}>Planning</h4>
                        </div>
                        <div className="widget-item">
                            <Unicons.UilDocumentLayoutCenter color={'#249ecc'}/>
                            <h4 style={{marginTop:'10px'}}>Mockup</h4>
                        </div>
                        <div className="widget-item">
                            <Unicons.UilFilter color={'#249ecc'}/>
                            <h4 style={{marginTop:'10px'}}>Develope</h4>
                        </div>
                        <div className="widget-item">
                            <Unicons.UilThumbsUp color={'#249ecc'}/>
                            <h4 style={{marginTop:'10px'}}>Provide</h4>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
